// src/pages/ChartPage.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { createChart } from 'lightweight-charts';
import qs from 'qs';
import Select, { components } from 'react-select';
import { Helmet } from 'react-helmet-async';
import './ChartPage.css';

// Importa le icone per i tipi di grafico
import { FaChartLine, FaChartArea } from 'react-icons/fa';
import { BsFillBarChartFill } from 'react-icons/bs';

const ChartPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('product');

  const chartContainerRef = useRef();
  const chartInstanceRef = useRef();

  // Stati per i dati
  const [klinesData, setKlinesData] = useState([]);

  // Opzioni per Timeframe e Chart Type con icone
  const timeframeOptions = [
    { value: '1D', label: '1D' },
    { value: '1W', label: '1W' },
    { value: '1M', label: '1M' },
    { value: '1Y', label: '1Y' },
  ];

  const chartTypeOptions = [
    { value: 'Candlestick', label: 'Candlestick', icon: <BsFillBarChartFill size={16} /> },
    { value: 'Line', label: 'Line', icon: <FaChartLine size={16} /> },
    { value: 'Area', label: 'Area', icon: <FaChartArea size={16} /> },
  ];

  // Stati per i controlli della toolbar
  const [timeframe, setTimeframe] = useState(timeframeOptions[0]); // Default a '1D'
  const [sources, setSources] = useState(null);
  const [chartType, setChartType] = useState(chartTypeOptions[2]); // Default a 'Area'

  // Stati per le sorgenti disponibili
  const [availableSources, setAvailableSources] = useState([]);

  // Stati di caricamento e errore
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Dettagli del prodotto per la presentazione
  const [productDetails, setProductDetails] = useState(null);

  // Riferimento per tenere traccia dell'AbortController corrente
  const abortControllerRef = useRef(null);

  // Funzione per recuperare le sorgenti disponibili per il prodotto selezionato
  useEffect(() => {
    const fetchAvailableSources = async () => {
      try {
        const response = await axios.get('https://api.agxfeed.com/v1/products');
        const products = response.data;

        // Trova il prodotto con il codice corrispondente
        const product = products.find((product) => product.code === code);

        if (product) {
          // Estrai le sorgenti dai dettagli del prodotto
          const sourcesSet = new Set();
          if (product.details && product.details.length > 0) {
            product.details.forEach((detail) => {
              if (detail.source && detail.source.code) {
                sourcesSet.add(detail.source.code);
              }
            });
          }

          // Converti il Set in Array
          const sourcesArray = Array.from(sourcesSet).map((code) => ({
            value: code,
            label: code,
          }));

          setAvailableSources(sourcesArray);
        } else {
          setAvailableSources([]);
        }
      } catch (error) {
        console.error('Errore nel recuperare le sorgenti disponibili:', error);
      }
    };

    fetchAvailableSources();
  }, [code]);

  // Funzione per recuperare i dati klines
  const fetchKlinesData = useCallback(async () => {
    // Annulla la richiesta precedente se esiste
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Crea un nuovo AbortController per la nuova richiesta
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    setIsLoading(true);
    setError(null);

    try {
      const params = {
        product: code,
        bucket: timeframe.value,
      };

      if (sources && sources.length > 0) {
        params.sources = sources.map((source) => source.value);
      }

      const response = await axios.get('https://api.agxfeed.com/v1/klines', {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        signal: abortController.signal, // Passa il segnale di abort
      });

      const formattedData = response.data.map((item) => ({
        time: item.sts,
        open: item.o,
        high: item.h,
        low: item.l,
        close: item.c,
      }));

      setKlinesData(formattedData);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Richiesta annullata');
      } else {
        console.error('Errore nel recuperare i dati klines:', error);
        setError('Errore nel caricamento dei dati.');
      }
    } finally {
      setIsLoading(false);
    }
  }, [code, timeframe, sources]);

  // Effettua il fetch iniziale e quando timeframe o sources cambiano
  useEffect(() => {
    if (code) {
      fetchKlinesData();
    }
  }, [code, fetchKlinesData]);

  // Recupera i dettagli del prodotto per la presentazione
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get('https://api.agxfeed.com/v1/products');
        const products = response.data;
        const product = products.find((p) => p.code === code);
        setProductDetails(product);
      } catch (error) {
        console.error('Errore nel recuperare i dettagli del prodotto:', error);
      }
    };

    if (code) {
      fetchProductDetails();
    }
  }, [code]);

  // Funzione per aggiungere una nuova serie basata sul tipo selezionato
  const addSeries = (chart, type) => {
    let series;
    switch (type) {
      case 'Line':
        series = chart.addLineSeries({
          color: '#2962FF',
          lineWidth: 2,
        });
        break;
      case 'Area':
        series = chart.addAreaSeries({
          topColor: 'rgba(171, 71, 188, 0.3)',
          bottomColor: 'rgba(171, 71, 188, 0.0)',
          lineColor: 'rgba(171, 71, 188, 1)',
          lineWidth: 2,
        });
        break;
      case 'Candlestick':
      default:
        series = chart.addCandlestickSeries();
        break;
    }
    return series;
  };

  // Inizializza o aggiorna il grafico
  useEffect(() => {
    if (chartContainerRef.current) {
      // Se il grafico esiste già, distruggilo e creane uno nuovo
      if (chartInstanceRef.current) {
        chartInstanceRef.current.chart.remove();
        chartInstanceRef.current = null;
      }

      // Crea un nuovo grafico
      const chart = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
        layout: {
          background: { type: 'solid', color: 'transparent' },
          textColor: '#000',
        },
        grid: {
          vertLines: {
            color: '#eee',
          },
          horzLines: {
            color: '#eee',
          },
        },
        timeScale: {
          rightOffset: 10,
          barSpacing: 10,
          visible: true,
          borderVisible: false,
        },
      });

      const series = addSeries(chart, chartType.value);
      chartInstanceRef.current = { chart, series };
    }
  }, [chartType]);

  // Aggiorna i dati del grafico quando klinesData cambia
  useEffect(() => {
    if (chartInstanceRef.current && klinesData.length > 0) {
      const { chart, series } = chartInstanceRef.current;

      series.setData(
        chartType.value === 'Candlestick'
          ? klinesData
          : klinesData.map((item) => ({
              time: item.time,
              value: item.close,
            }))
      );

      chart.timeScale().fitContent();
    }
  }, [klinesData, chartType]);

  // Usa ResizeObserver per monitorare i cambiamenti delle dimensioni del contenitore
  useEffect(() => {
    if (chartContainerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        const { width, height } = entries[0].contentRect;
        if (chartInstanceRef.current) {
          chartInstanceRef.current.chart.resize(width, height);
        }
      });

      resizeObserver.observe(chartContainerRef.current);

      return () => {
        if (chartContainerRef.current) {
          resizeObserver.unobserve(chartContainerRef.current);
        }
      };
    }
  }, []);

  // Gestori degli input
  const handleChartTypeChange = (selectedOption) => {
    setChartType(selectedOption);
  };

  const handleTimeframeChange = (selectedOption) => {
    setTimeframe(selectedOption);
  };

  const handleSourcesChange = (selectedOptions) => {
    setSources(selectedOptions);
  };

  // Componenti personalizzati per le opzioni di Chart Type
  const ChartTypeOption = (props) => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {props.data.icon}
          <span style={{ marginLeft: 8 }}>{props.data.label}</span>
        </div>
      </components.Option>
    );
  };

  // Componente per il valore selezionato di Chart Type
  const ChartTypeSingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {props.data.icon}
          {/* Se vuoi mostrare anche il label accanto all'icona, decommenta la riga seguente */}
          {/* <span style={{ marginLeft: 8 }}>{props.data.label}</span> */}
        </div>
      </components.SingleValue>
    );
  };

  return (
    <div className="chart-page">
      <Helmet>
        <title>
          {productDetails ? `${productDetails.name} | AGXFeed` : 'Caricamento... | AGXFeed'}
        </title>
        <meta
          name="description"
          content={
            productDetails
              ? `Visualizza il grafico e i dati storici per ${productDetails.name} su AGXFeed.`
              : 'Visualizza il grafico e i dati storici su AGXFeed.'
          }
        />
      </Helmet>

      {/* Sezione Informativa del Prodotto */}
      {productDetails && (
        <div className="product-info">
          <h2 className="product-name">{productDetails.name}</h2>
          <p className="product-category">
            {productDetails.macrocategory} &gt; {productDetails.category}
          </p>
        </div>
      )}

      {/* Contenuto principale */}
      <div className="content">
        {/* Toolbar */}
        <div className="toolbar">
          <Select
            options={timeframeOptions}
            value={timeframe}
            onChange={handleTimeframeChange}
            className="toolbar-select"
            classNamePrefix="toolbar-select"
            isSearchable={false} // Disabilita l'inserimento di testo
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (base) => ({ ...base, minWidth: '100px' }),
            }}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
          />

          <Select
            isMulti
            options={availableSources}
            value={sources}
            onChange={handleSourcesChange}
            placeholder="Sorgenti"
            className="sources-select"
            classNamePrefix="sources-select"
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (base) => ({
                ...base,
                minHeight: '38px',
                minWidth: '150px',
              }),
              valueContainer: (base) => ({
                ...base,
                maxHeight: '80px',
                overflowY: 'auto',
              }),
            }}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
          />

          <Select
            options={chartTypeOptions}
            value={chartType}
            onChange={handleChartTypeChange}
            className="toolbar-select"
            classNamePrefix="toolbar-select"
            isSearchable={false} // Disabilita l'inserimento di testo
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (base) => ({ ...base, minWidth: '120px' }),
            }}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            components={{ Option: ChartTypeOption, SingleValue: ChartTypeSingleValue }}
          />
        </div>
        {/* Contenitore del grafico */}
        <div className="chart-container">
          {isLoading && <p>Caricamento dati...</p>}
          {error && <p className="error">{error}</p>}

          {/* Watermark personalizzato */}
          <div className="custom-watermark">AGXFeed</div>

          <div id="chart" ref={chartContainerRef}></div>
        </div>
      </div>
    </div>
  );
};

export default ChartPage;
