// src/pages/About.js

import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  FaSeedling,
  FaChartLine,
  FaChartArea,
  FaBullseye,
  FaRocket,
  FaTools,
  FaCheckCircle,
  FaCode,
} from 'react-icons/fa';
import HeroSection from '../components/HeroSection'; // Importa il componente
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <Helmet>
        <title>Chi Siamo | AGXFeed</title>
        <meta
          name="description"
          content="AGXFeed è una REST API che aggrega dati dalle borse merci italiane, offrendo informazioni aggiornate e affidabili per sviluppatori, trader e professionisti del settore agricolo."
        />
      </Helmet>

      {/* Hero Section */}
      <HeroSection
        title="AGXFeed"
        subtitle="Dati aggregati per decisioni informate."
      />

      {/* Mission Section */}
      <section className="mission-section">
        <h2>
          <FaBullseye /> La Nostra Missione
        </h2>
        <p>
          AGXFeed nasce con l’obiettivo di digitalizzare i mercati agricoli italiani. Offriamo una <strong>REST API</strong> avanzata che aggrega e analizza i dati di mercato, fornendo strumenti essenziali per l’analisi dei dati e supportando agricoltori, trader e professionisti nel prendere decisioni informate basate su informazioni precise e aggiornate.
        </p>
      </section>

      {/* Cosa Facciamo Section */}
      <section className="what-we-do-section">
        <h2>
          <FaChartLine /> Cosa Facciamo
        </h2>
        <div className="services-grid">
          {/* Servizio REST API */}
          <div className="service-item">
            <div className="service-icon">
              <FaTools />
            </div>
            <div className="service-content">
              <h3>Servizio REST API</h3>
              <p>
                AGXFeed offre una <strong>REST API</strong> che consente l'accesso ai prodotti disponibili nelle sedi di scambio agricolo in Italia, come la Borsa Merci di Bologna e Verona, e associazioni granarie come Milano. La nostra API fornisce dati puntuali pubblicati dagli enti o aggregati, restituendoli all’utente in meno di un secondo.
              </p>
            </div>
          </div>

          {/* Analisi dei Dati di Mercato */}
          <div className="service-item">
            <div className="service-icon">
              <FaChartArea />
            </div>
            <div className="service-content">
              <h3>Analisi dei Dati di Mercato</h3>
              <p>
                I dati finanziari forniti da AGXFeed sono fondamentali per l’analisi di mercato e la pianificazione colturale. Integrati con altri strumenti finanziari, supportano il funzionamento di bot e permettono agli agricoltori, sia autonomi che assistiti da consulenti finanziari, di testare strategie operative per massimizzare i profitti o minimizzare i rischi legati alla produzione e alla vendita. Inoltre, questi dati possono essere utilizzati come base per lo sviluppo di prodotti derivati come i futures, grazie alla possibilità di salvare i dati spot.
              </p>
            </div>
          </div>

          {/* Flessibilità e Velocità */}
          <div className="service-item">
            <div className="service-icon">
              <FaChartLine />
            </div>
            <div className="service-content">
              <h3>Flessibilità e Velocità</h3>
              <p>
                Il nostro servizio REST API permette di caricare i dati direttamente sul sito di AGXFeed e di eseguire rappresentazioni personalizzate. Che tu abbia bisogno di dati specifici per Verona, Milano o per l'intera Italia, AGXFeed offre la flessibilità necessaria per soddisfare le tue esigenze, rendendo accessibili dati precisi e tempestivi a borse merci, software gestionali, analisti di mercato e ricercatori.
              </p>
            </div>
          </div>

          {/* Unicità nel Mercato */}
          <div className="service-item">
            <div className="service-icon">
              <FaCheckCircle />
            </div>
            <div className="service-content">
              <h3>Unicità nel Mercato</h3>
              <p>
                Siamo il primo servizio REST API per i prezzi agricoli disponibile al pubblico in Italia, distinguendoci da concorrenti come Agriprezzi grazie alla nostra velocità, affidabilità e facilità d’uso.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Benefici Section */}
      <section className="why-choose-us-section">
        <h2>
          <FaBullseye /> Perché Scegliere AGXFeed
        </h2>
        <div className="benefits-grid">
          <div className="benefit-item">
            <FaCheckCircle className="benefit-icon" />
            <h3>Decisioni Informate</h3>
            <p>Accesso a dati accurati e aggiornati per supportare decisioni basate su informazioni reali.</p>
          </div>
          <div className="benefit-item">
            <FaCheckCircle className="benefit-icon" />
            <h3>Facilità di Integrazione</h3>
            <p>API intuitiva che permette un’integrazione semplice nei tuoi progetti e applicazioni, risparmiando tempo e risorse.</p>
          </div>
          <div className="benefit-item">
            <FaCheckCircle className="benefit-icon" />
            <h3>Supporto alla Crescita</h3>
            <p>Strumenti avanzati per analizzare le tendenze del mercato, supportando la crescita e la sostenibilità delle attività agricole.</p>
          </div>
        </div>
      </section>

      {/* Accesso alla REST API Section */}
      <section className="api-access-section">
        <h2>
          <FaCheckCircle /> Accesso alla REST API
        </h2>
        <p>
          AGXFeed offre accesso gratuito alle nostre REST API per promuovere l'utilizzo e permettere agli utenti di familiarizzare con le nostre funzionalità. Approfitta di questa opportunità per integrare dati agricoli affidabili nei tuoi progetti.
        </p>
        <h3>Come Effettuare una Query</h3>
        <p>
          Per ottenere i dati giornalieri dei semi di soia nazionale da tutte le sorgenti di dati (prezzi) presenti in Italia, effettua una richiesta <strong>GET</strong> al seguente endpoint:
        </p>
        <pre>
          <code>
            GET https://api.agxfeed.com/v1/klines?product=SSN&bucket=1D
          </code>
        </pre>
        <p>
          <strong>Parametri:</strong>
        </p>
        <ul>
          <li><code>product</code> (required): Codice del prodotto, es: <code>SSN</code></li>
          <li><code>bucket</code> (required): Intervallo di aggregazione. Valori disponibili: <code>1D</code>, <code>1W</code>, <code>1M</code>, <code>1Y</code></li>
          <li><code>sources</code>: Lista di codici sorgente. Opzioni disponibili: <code>VR</code>, <code>BO</code>, <code>MI</code></li>
          <li><code>starttime</code>: Unix timestamp di inizio per filtrare i dati</li>
          <li><code>endtime</code>: Unix timestamp di fine per filtrare i dati</li>
          <li><code>limit</code>: Numero massimo di record da restituire (1..1000, Default: 500)</li>
        </ul>
        <p>
          <strong>Esempio di Utilizzo:</strong>
        </p>
        <pre>
          <code>
            GET https://api.agxfeed.com/v1/klines?product=SSN&bucket=1D
          </code>
        </pre>
        <p>
          Questa richiesta restituisce i dati giornalieri aggregati dai vari mercati e dalla nostra base di dati, permettendoti di analizzare le tendenze dei prezzi dei semi di soia a livello nazionale.
        </p>
        <p>
          Consulta la nostra <a href="https://api.agxfeed.com/docs" target="_blank" rel="noopener noreferrer">documentazione ufficiale</a> per ulteriori dettagli e per scoprire tutte le funzionalità offerte dalle nostre REST API.
        </p>
      </section>

      {/* Esempi di Utilizzo Section */}
      <section className="examples-section">
        <h2>
          <FaCode /> Esempi di Utilizzo
        </h2>
        <p>
          Scopri come integrare le REST API di AGXFeed nei tuoi progetti con questi esempi pratici in diversi linguaggi di programmazione.
        </p>
        <a href="/examples" className="examples-link">
          Vedi Esempi
        </a>
      </section>

      {/* Futuro Milestones Section */}
      <section className="future-section">
        <h2>
          <FaRocket /> Il Nostro Futuro
        </h2>
        <p>
          Una delle nostre principali mete future è l’integrazione dei dati sui volumi di mercato, oltre ai prezzi. Questo permetterà di offrire una qualità e una granularità dei dati senza precedenti, facilitando l’osservazione dei movimenti dei principali operatori del mercato italiano. Anche se il mercato agricolo italiano non è particolarmente liquido, riteniamo che questa miglioria possa attrarre sia compratori che venditori, fornendo informazioni più dettagliate rispetto ai tradizionali bollettini PDF.
        </p>
        <p>
          Continueremo a migliorare e ampliare i nostri servizi, con l’obiettivo di diventare un punto di riferimento per l’accesso ai dati dei mercati agricoli, supportando l’innovazione e l’efficienza nel settore.
        </p>
      </section>

      {/* Chi Sono Section */}
      <section className="founder-section">
        <h2>
          <FaSeedling /> Chi Sono
        </h2>
        <p>
          Mi chiamo <strong>Alvise Giovanni Carazzolo</strong>, dottore agronomo laureato magistrale presso l'Università degli Studi di Padova. AGXFeed è nato da una necessità personale: ottenere rapidamente e in tempo reale i prezzi dei prodotti agricoli prodotti dall'azienda di famiglia. Questo strumento è fondamentale per implementare indicatori derivati da formule matematiche e condurre analisi dettagliate, permettendo decisioni economiche strategiche sulla vendita in determinati periodi.
        </p>
      </section>
    </div>
  );
};

export default About;
