// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FiBook, FiInfo } from 'react-icons/fi'; // Importa le icone per Docs e About
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <Link to="/" className="navbar-logo">
        AGXFeed
      </Link>
      <div className="navbar-links">
        {/* Link Docs */}
        <a href="https://api.agxfeed.com/docs" target="_blank" rel="noopener noreferrer" className="navbar-link">
          <FiBook className="navbar-icon" />
          Docs
        </a>
        {/* Link About */}
        <Link to="/about" className="navbar-link">
          <FiInfo className="navbar-icon" />
          About
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
