// src/pages/Home.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  FaChartBar,
  FaChartLine,
  FaPlusCircle,
  FaMinusCircle,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import HeroSection from '../components/HeroSection';
import './Home.css';

const Home = () => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedMacros, setExpandedMacros] = useState({});
  const [expandedCategories, setExpandedCategories] = useState({});

  // Recupera i prodotti disponibili dall'API
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://api.agxfeed.com/v1/products');
        setProducts(response.data);
      } catch (error) {
        console.error('Errore nel recuperare i prodotti:', error);
        setError('Errore nel recuperare i prodotti.');
      }
    };

    fetchProducts();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    // Resetta le espansioni quando cambia la ricerca
    setExpandedMacros({});
    setExpandedCategories({});
  };

  // Filtra i prodotti in base alla query di ricerca
  const filteredProducts = products.filter((product) => {
    const query = searchQuery.toLowerCase();
    return (
      product.code.toLowerCase().includes(query) ||
      (product.macrocategory &&
        product.macrocategory.toLowerCase().includes(query)) ||
      (product.category && product.category.toLowerCase().includes(query)) ||
      product.name.toLowerCase().includes(query)
    );
  });

  // Crea una struttura gerarchica
  const hierarchicalProducts = filteredProducts.reduce((acc, product) => {
    const macro = product.macrocategory || 'Altri';
    const category = product.category || 'Altri';

    if (!acc[macro]) {
      acc[macro] = {};
    }
    if (!acc[macro][category]) {
      acc[macro][category] = [];
    }
    acc[macro][category].push(product);
    return acc;
  }, {});

  const toggleMacro = (macro) => {
    setExpandedMacros((prev) => ({
      ...prev,
      [macro]: !prev[macro],
    }));
  };

  const toggleCategory = (macro, category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [`${macro}-${category}`]: !prev[`${macro}-${category}`],
    }));
  };

  return (
    <div className="home-container">
      {/* Impostazione del titolo e meta tag specifici per la Home */}
      <Helmet>
        <title>AGXFeed - Informazioni per un mercato più consapevole</title>
        <meta
          name="description"
          content="AGXFeed fornisce dati aggregati sui prezzi delle materie prime agricole, offrendo informazioni accessibili per un mercato più consapevole."
        />
      </Helmet>

      {/* Hero Section */}
      <HeroSection
        title="AGXFeed"
        subtitle="Informazioni accessibili per un mercato più consapevole"
      />

      {/* Messaggio di errore se presente */}
      {error && <p className="error-message">{error}</p>}

      {/* Sezione dei prodotti */}
      <div className="products-section">
        <h2>Prodotti Disponibili</h2>

        {/* Barra di ricerca */}
        <div className="search-bar">
          <input
            type="text"
            placeholder="Cerca prodotti..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        <div className="products-table-container">
          <table className="products-table">
            <tbody>
              {Object.keys(hierarchicalProducts).map((macro) => (
                <React.Fragment key={macro}>
                  {/* Riga della macrocategoria */}
                  <tr onClick={() => toggleMacro(macro)} className="macro-row">
                    <td colSpan="3">
                      <span className="expand-icon">
                        {expandedMacros[macro] ? (
                          <FaMinusCircle />
                        ) : (
                          <FaPlusCircle />
                        )}
                      </span>
                      {macro}
                    </td>
                  </tr>
                  {expandedMacros[macro] &&
                    Object.keys(hierarchicalProducts[macro]).map((category) => (
                      <React.Fragment key={category}>
                        {/* Riga della categoria */}
                        <tr
                          onClick={() => toggleCategory(macro, category)}
                          className="category-row"
                        >
                          <td style={{ paddingLeft: '40px' }} colSpan="3">
                            <span className="expand-icon">
                              {expandedCategories[`${macro}-${category}`] ? (
                                <FaMinusCircle />
                              ) : (
                                <FaPlusCircle />
                              )}
                            </span>
                            {category}
                          </td>
                        </tr>
                        {expandedCategories[`${macro}-${category}`] &&
                          hierarchicalProducts[macro][category].map(
                            (product) => (
                              <tr key={product.id} className="product-row">
                                <td style={{ paddingLeft: '60px' }}>
                                  <strong>{product.code}</strong>
                                </td>
                                <td>{product.name}</td>
                                <td>
                                  <Link
                                    to={`/chart?product=${product.code}`}
                                    className="view-icon"
                                    title={`Visualizza grafico di ${product.name}`}
                                  >
                                    <FaChartLine size={20} />
                                  </Link>
                                  <Link
                                    to={`/market_profile?product=${product.code}`}
                                    className="view-icon"
                                    title={`Visualizza Market Profile di ${product.name}`}
                                    style={{ marginLeft: '10px' }}
                                  >
                                    <FaChartBar size={20} />
                                  </Link>
                                </td>
                              </tr>
                            )
                          )}
                      </React.Fragment>
                    ))}
                </React.Fragment>
              ))}
              {/* Messaggio se non ci sono prodotti corrispondenti alla ricerca */}
              {filteredProducts.length === 0 && (
                <tr>
                  <td colSpan="3">Nessun prodotto trovato.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Home;
