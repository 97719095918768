
// src/pages/Examples.js

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FaCode, FaPython, FaJsSquare } from 'react-icons/fa';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
import javascript from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Examples.css';

// Registrazione dei linguaggi
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('javascript', javascript);

const Examples = () => {
  const pythonExample = `
import requests

def get_soybean_prices():
    url = "https://api.agxfeed.com/v1/klines"
    params = {
        "product": "SSN",
        "bucket": "1D"
    }
    response = requests.get(url, params=params)
    data = response.json()
    return data

if __name__ == "__main__":
    prices = get_soybean_prices()
    print(prices)
  `;

  const javascriptExample = `
// Importazione della libreria axios
const axios = require('axios');

async function getSoybeanPrices() {
  try {
    const response = await axios.get('https://api.agxfeed.com/v1/klines', {
      params: {
        product: 'SSN',
        bucket: '1D'
      }
    });
    const data = response.data;
    console.log(data);
  } catch (error) {
    console.error('Errore nella richiesta:', error);
  }
}

getSoybeanPrices();
  `;

  return (
    <div className="examples-container">
      <Helmet>
        <title>Esempi | AGXFeed</title>
        <meta
          name="description"
          content="Scopri come integrare le REST API di AGXFeed nei tuoi progetti con esempi pratici in Python e JavaScript."
        />
      </Helmet>

      {/* Header della Pagina */}
      <header className="examples-header">
        <h1>
          <FaCode /> Esempi di Utilizzo
        </h1>
        <p>
          Scopri come integrare le REST API di AGXFeed nei tuoi progetti con questi esempi pratici in diversi linguaggi di programmazione.
        </p>
      </header>

      {/* Esempio Python */}
      <section className="example-section">
        <h2>
          <FaPython /> Esempio in Python
        </h2>
        <p>
          Questo esempio mostra come effettuare una richiesta GET all'endpoint delle REST API di AGXFeed utilizzando la libreria <strong>requests</strong>.
        </p>
        <SyntaxHighlighter language="python" style={docco}>
          {pythonExample}
        </SyntaxHighlighter>
        <img src="/images/python_example.png" alt="Esempio Python" className="example-image" />
      </section>

      {/* Esempio JavaScript */}
      <section className="example-section">
        <h2>
          <FaJsSquare /> Esempio in JavaScript
        </h2>
        <p>
          Questo esempio mostra come effettuare una richiesta GET all'endpoint delle REST API di AGXFeed utilizzando la libreria <strong>axios</strong>.
        </p>
        <SyntaxHighlighter language="javascript" style={docco}>
          {javascriptExample}
        </SyntaxHighlighter>
        <img src="/images/javascript_example.png" alt="Esempio JavaScript" className="example-image" />
      </section>

      {/* Aggiungi altri esempi qui */}
    </div>
  );
};

export default Examples;
