// src/pages/MarketProfilePage.js

import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import * as d3 from 'd3';
import './MarketProfilePage.css';

const MarketProfilePage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('product');

  const [flatMpData, setFlatMpData] = useState(null);
  const [error, setError] = useState(null);

  const heatmapRef = useRef(null);

  // Funzione per elaborare i dati
  const processMarketProfileData = (days) => {
    const letters = Array.from({ length: days.length }, (_, i) => i + 1);

    const lprice = Math.floor(Math.min(...days.map((day) => day.l)));
    const hprice = Math.ceil(Math.max(...days.map((day) => day.h)));

    const prices = [];
    for (let price = lprice; price <= hprice; price += 1) {
      prices.push(price);
    }

    const preMp = [];

    days.forEach((day, index) => {
      const letter = letters[index];
      prices.forEach((price) => {
        if (day.l <= price && price <= day.h) {
          preMp.push({ letter, price });
        }
      });
    });

    const marketProfile = prices.map((price) => ({
      price,
      letters: [],
    }));

    preMp.forEach((tup) => {
      const mp = marketProfile.find((item) => item.price === tup.price);
      if (mp) {
        mp.letters.push(tup.letter);
      }
    });

    const flatMp = { price: [], column: [], value: [] };

    marketProfile.forEach((mp) => {
      mp.letters.forEach((value, index) => {
        const col = index + 1;
        flatMp.price.push(mp.price);
        flatMp.column.push(col);
        flatMp.value.push(value);
      });
    });

    // Convertiamo flatMp in un array di oggetti per facilitare l'uso con D3.js
    const flatMpArray = flatMp.price.map((price, index) => ({
      price,
      column: flatMp.column[index],
      value: flatMp.value[index],
    }));

    return flatMpArray;
  };

  // Funzione per recuperare i dati dall'API
  useEffect(() => {
    const fetchMarketProfileData = async () => {
      try {
        const response = await axios.get('https://api.agxfeed.com/v1/hl', {
          params: {
            product: code,
            bucket: '1D',
          },
          paramsSerializer: (params) => {
            // Serializza gli array come parametri ripetuti
            const queryString = Object.keys(params)
              .map((key) => {
                const value = params[key];
                if (Array.isArray(value)) {
                  return value
                    .map(
                      (v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`
                    )
                    .join('&');
                } else if (value !== null && value !== undefined) {
                  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
                }
                return '';
              })
              .filter(Boolean)
              .join('&');
            return queryString;
          },
        });

        const days = response.data;
        const flatMp = processMarketProfileData(days);
        setFlatMpData(flatMp);
      } catch (error) {
        console.error('Errore nel recuperare i dati:', error);
        setError('Errore nel caricamento dei dati.');
      }
    };

    if (code) {
      fetchMarketProfileData();
    }
  }, [code]);

  // Funzione per disegnare la heatmap con D3.js
  useEffect(() => {
    if (flatMpData && heatmapRef.current) {
      // Pulire il contenitore prima di disegnare
      d3.select(heatmapRef.current).selectAll('*').remove();

      // Impostare le dimensioni e i margini del grafico
      const margin = { top: 20, right: 30, bottom: 50, left: 60 };
      const width = 800 - margin.left - margin.right;
      const height = 600 - margin.top - margin.bottom;

      // Creare l'area SVG
      const svg = d3
        .select(heatmapRef.current)
        .append('svg')
        .attr('width', '100%')
        .attr(
          'height',
          height + margin.top + margin.bottom + 60 // Spazio extra per la legenda
        )
        .attr(
          'viewBox',
          `0 0 ${width + margin.left + margin.right} ${
            height + margin.top + margin.bottom + 60
          }`
        )
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      // Creare gli assi X e Y
      const xValues = Array.from(new Set(flatMpData.map((d) => d.column))).sort(
        (a, b) => a - b
      );
      const yValues = Array.from(
        new Set(flatMpData.map((d) => d.price))
      ).sort((a, b) => b - a); // Ordine decrescente per avere i prezzi alti in alto

      const xScale = d3
        .scaleBand()
        .range([0, width])
        .domain(xValues)
        .padding(0.05);

      const yScale = d3
        .scaleBand()
        .range([0, height])
        .domain(yValues)
        .padding(0.05);

      // Personalizza gli assi Y per mostrare solo alcuni valori
      const yAxis = d3
        .axisLeft(yScale)
        .tickValues(
          yScale.domain().filter((d, i) => {
            const totalTicks = 10; // Numero totale di tick desiderati
            const interval = Math.ceil(yScale.domain().length / totalTicks);
            return (
              i % interval === 0 || i === 0 || i === yScale.domain().length - 1
            );
          })
        )
        .tickFormat((d) => d);

      svg
        .append('g')
        .call(yAxis)
        .select('.domain')
        .remove();

      svg
        .append('g')
        .attr('transform', `translate(0, ${height})`)
        .call(d3.axisBottom(xScale).tickSize(0).tickValues([])) // Rimuove i tick sull'asse X
        .select('.domain')
        .remove();

      // Creare la scala dei colori
      const minValue = d3.min(flatMpData, (d) => d.value);
      const maxValue = d3.max(flatMpData, (d) => d.value);

      const colorScale = d3
        .scaleSequential(d3.interpolateMagma)
        .domain([minValue, maxValue]);

      // Aggiungere un elemento tooltip
      const tooltip = d3
        .select(heatmapRef.current)
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0);

      // Aggiungere un gruppo per il crosshair orizzontale
      const crosshair = svg
        .append('line')
        .attr('class', 'crosshair')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', 0)
        .attr('y2', 0)
        .style('stroke', '#000')
        .style('stroke-width', 1)
        .style('opacity', 0);

      // Aggiungere i rettangoli per la heatmap
      svg
        .selectAll()
        .data(flatMpData, (d) => `${d.column}:${d.price}`)
        .enter()
        .append('rect')
        .attr('class', 'heatmap-cell') // Aggiunto per identificare le celle della heatmap
        .attr('x', (d) => xScale(d.column))
        .attr('y', (d) => yScale(d.price))
        .attr('width', xScale.bandwidth())
        .attr('height', yScale.bandwidth())
        .style('fill', (d) => colorScale(d.value))
        .on('mouseover', function (event, d) {
          d3.select(this).style('stroke', '#000').style('stroke-width', 1);

          // Posizionamento del tooltip
          const [mouseX, mouseY] = d3.pointer(event, heatmapRef.current);
          const tooltipWidth = 100; // Larghezza stimata del tooltip
          const tooltipHeight = 50; // Altezza stimata del tooltip
          const containerWidth = heatmapRef.current.offsetWidth;
          const containerHeight = heatmapRef.current.offsetHeight;

          let tooltipX = mouseX + 15;
          let tooltipY = mouseY;

          // Aggiustamento se il tooltip esce a destra
          if (tooltipX + tooltipWidth > containerWidth) {
            tooltipX = mouseX - tooltipWidth - 15;
          }

          // Aggiustamento se il tooltip esce in basso
          if (tooltipY + tooltipHeight > containerHeight) {
            tooltipY = mouseY - tooltipHeight;
          }

          tooltip
            .style('opacity', 1)
            .html(
              `Prezzo: ${d.price}<br/>Frequenza: ${d.column}<br/>Valore: ${d.value}`
            )
            .style('left', tooltipX + 'px')
            .style('top', tooltipY + 'px');

          // Mostra e posiziona il crosshair
          crosshair
            .attr('y1', yScale(d.price) + yScale.bandwidth() / 2)
            .attr('y2', yScale(d.price) + yScale.bandwidth() / 2)
            .style('opacity', 1);
        })
        .on('mousemove', function (event) {
          // Aggiorna la posizione del tooltip
          const [mouseX, mouseY] = d3.pointer(event, heatmapRef.current);
          const tooltipWidth = 100;
          const tooltipHeight = 50;
          const containerWidth = heatmapRef.current.offsetWidth;
          const containerHeight = heatmapRef.current.offsetHeight;

          let tooltipX = mouseX + 15;
          let tooltipY = mouseY;

          if (tooltipX + tooltipWidth > containerWidth) {
            tooltipX = mouseX - tooltipWidth - 15;
          }

          if (tooltipY + tooltipHeight > containerHeight) {
            tooltipY = mouseY - tooltipHeight;
          }

          tooltip
            .style('left', tooltipX + 'px')
            .style('top', tooltipY + 'px');
        })
        .on('mouseout', function () {
          d3.select(this).style('stroke', 'none');
          tooltip.style('opacity', 0);
          crosshair.style('opacity', 0);
        });

      // Aggiungere la legenda della scala di colori sotto il grafico
      const legendWidth = width;
      const legendHeight = 20;

      const legend = svg
        .append('g')
        .attr('transform', `translate(0, ${height + 30})`); // Posizionato sotto il grafico

      const legendScale = d3
        .scaleLinear()
        .domain([minValue, maxValue])
        .range([0, legendWidth]);

      // Creare un gradiente per la legenda
      const legendGradientId = 'legend-gradient';

      svg
        .append('defs')
        .append('linearGradient')
        .attr('id', legendGradientId)
        .attr('x1', '0%')
        .attr('y1', '0%')
        .attr('x2', '100%')
        .attr('y2', '0%')
        .selectAll('stop')
        .data(
          d3.ticks(minValue, maxValue, 10).map((value) => ({
            offset: ((value - minValue) / (maxValue - minValue)) * 100 + '%',
            color: colorScale(value),
          }))
        )
        .enter()
        .append('stop')
        .attr('offset', (d) => d.offset)
        .attr('stop-color', (d) => d.color);

      legend
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', legendWidth)
        .attr('height', legendHeight)
        .style('fill', `url(#${legendGradientId})`)
        .on('mousemove', function (event) {
          const mouseX = d3.pointer(event, this)[0];
          const valueAtMouse = legendScale.invert(mouseX);
          // Trova le celle con valori vicini
          const threshold = (maxValue - minValue) / 20; // Regola il threshold come preferisci
          svg.selectAll('.heatmap-cell') // Seleziona solo le celle della heatmap
            .style('opacity', (d) =>
              Math.abs(d.value - valueAtMouse) < threshold ? 1 : 0.2
            );
        })
        .on('mouseout', function () {
          svg.selectAll('.heatmap-cell').style('opacity', 1);
        });

      // Aggiungere l'asse della legenda
      const legendAxis = d3
        .axisBottom(legendScale)
        .ticks(10)
        .tickFormat(d3.format('.0f'));

      legend
        .append('g')
        .attr('transform', `translate(0, ${legendHeight})`)
        .call(legendAxis)
        .select('.domain')
        .remove(); // Rimuove la linea orizzontale dell'asse

      // Assicurarsi che i numeri dell'asse siano completamente visibili
      legend
        .selectAll('text')
        .style('text-anchor', 'middle')
        .attr('dy', '1em'); // Sposta i numeri verso il basso
    }
  }, [flatMpData]);

  return (
    <div className="market-profile-page">
      <div className="content">
        <h2>Market Profile per {code}</h2>
        <div className="heatmap-container" ref={heatmapRef}></div>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default MarketProfilePage;
